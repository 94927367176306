
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonAvatar, IonIcon, IonButton, IonButtons, menuController } from "@ionic/vue";
import { useRouter } from "vue-router";

import { menu } from "ionicons/icons";

import { dbUser, dbCustomers, dbDocuments, dbRichieste, dbPresenze, dbTimbrature } from "../services/localbase";

import moment from "moment";

export default {
  name: "Profilo",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonAvatar,
    IonIcon,
    IonButton,
    IonButtons,
  },
  setup() {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const dipendenteID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_id;
    const userImage = `${process.env.VUE_APP_BASE_URL}/uploads/${user.dipendenti_foto}`;

    const router = useRouter();

    const openMenu = () => {
      menuController.open("app-menu");
    };

    /**
     * Set user avatar image
     */
    function setImage(user) {
      if (user.dipendenti_foto) {
        return `${process.env.VUE_APP_BASE_URL}/uploads/${user.dipendenti_foto}`;
      } else {
        return `${process.env.VUE_APP_BASE_URL}/images/user.png`;
      }
    }

    /**
     * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
     */
    function dateFormat(date) {
      if (date && moment.isDate(new Date(date))) {
        return moment(date).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    }

    /**
     * Return '-' if a customer field is null
     */
    function checkField(field) {
      if (!field || field === "") {
        return " - ";
      } else {
        return field;
      }
    }

    /**
     * Force app update
     */
    function updateApp() {
      window.location.reload(true);
    }

    /**
     * Delete localbase CLIENTI and USER database
     */
    async function deleteDb() {
      /*       const deleteUser = await dbUser.collection("user").delete();
      const deleteDocuments = await dbDocuments
        .collection("documents")
        .delete();
      const deleteRichieste = await dbRichieste
        .collection("richieste")
        .delete();
      const deletePresenze = await dbPresenze.collection("presenze").delete();
      const deleteTimbrature = await dbTimbrature
        .collection("timbrature")
        .delete(); */

      dbUser.delete().then((response) => {
        console.log(response);
      });
      dbDocuments.delete().then((response) => {
        console.log(response);
      });
      dbRichieste.delete().then((response) => {
        console.log(response);
      });
      dbPresenze.delete().then((response) => {
        console.log(response);
      });
      dbTimbrature.delete().then((response) => {
        console.log(response);
      });
    }

    /**
     * Delete Localbase databases and clear localStorage
     * Redirect user to Login page
     */
    function logOut() {
      deleteDb().then(() => {
        localStorage.clear();
        router.replace("/login");
      });
    }

    return {
      user,
      setImage,
      userImage,
      dateFormat,
      checkField,
      logOut,
      updateApp,
      menu,
      openMenu,
    };
  },
};
